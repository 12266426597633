import React, { useState } from 'react';
import { Bell, DollarSign, Clock, Users, Repeat, Trophy, ArrowUpCircle, ArrowDownCircle, Copy, Check } from 'lucide-react';

const SmartMoneyCard = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [copied, setCopied] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  const copyCA = async () => {
    const ca = "9yDfJ3f8DmtNLj2D47g9TkVet51MxYQDLMYBeecrpump";
    await navigator.clipboard.writeText(ca);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-purple-700 to-indigo-800 p-4">
      <div className="w-full max-w-2xl bg-gray-800 text-white shadow-xl relative rounded-lg">
        <div className="bg-gradient-to-r from-pink-500 to-orange-500 rounded-t-lg p-4">
          <h2 className="text-2xl font-bold flex items-center">
            <Bell className="mr-2" /> 3ª Alerta - Smart Money Compró BFF
          </h2>
        </div>
        <div className="p-6 space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <InfoItem icon={<Users />} label="Smart money" value="VisFB" />
            <InfoItem 
              icon={<DollarSign />} 
              label="CA" 
              value={
                <div className="flex items-center">
                  <span className="mr-2">9yDfJ3f8Dm...pump</span>
                  <button
                    onClick={copyCA}
                    className={`transition-colors ${copied ? 'text-green-400' : 'text-gray-400 hover:text-white'}`}
                    title={copied ? 'Copiado!' : 'Copiar CA'}
                  >
                    {copied ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              } 
            />
            <InfoItem icon={<DollarSign />} label="Nombre/Símbolo" value="Best Friends/BFF" />
            <InfoItem icon={<DollarSign />} label="Compró" value="2.97 SOL" />
            <InfoItem icon={<DollarSign />} label="FDV" value="$91.87K" />
            <InfoItem icon={<Clock />} label="Fecha y Hora (UTC)" value="7/15/2024, 12:08:54 AM" />
          </div>
          
          <div className="mt-6 bg-gray-700 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-3">Resumen de Smart Moneys</h3>
            <div className="grid grid-cols-2 gap-4">
              <InfoItem icon={<Users />} label="Número de smart moneys" value="2" />
              <InfoItem icon={<DollarSign />} label="Monto total comprado" value="9.90 SOL" />
              <InfoItem icon={<Repeat />} label="Total de compras" value="3" />
              <InfoItem icon={<Trophy />} label="Monto máximo comprado" value="4.9500 SOL" />
            </div>
          </div>
        </div>
        <div className="flex justify-center p-4">
          <button onClick={togglePopup} className="bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-white font-bold py-2 px-4 rounded">
            Ver historial de C/V: 5 | 2
          </button>
        </div>

        {showPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-gray-800 p-6 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-hidden">
              <h2 className="text-2xl font-bold mb-4 text-white">Historial de Compras y Ventas</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-white">Compras</h3>
                  <div className="bg-gray-700 p-4 rounded-lg h-[400px] overflow-y-auto">
                    {[
                      { smartMoney: 'VisFB', amount: '2.97', price: '$0.00009516', fdv: '91.87K', time: '7/15/2024, 12:08:54 AM' },
                      { smartMoney: 'npUau', amount: '1.98', price: '$0.00014694', fdv: '146.94K', time: 'Hace 12m 5s' },
                      { smartMoney: 'VisFB', amount: '4.95', price: '$0.00012531', fdv: '125.31K', time: 'Hace 12m 14s' },
                    ].map((item, index) => (
                      <div key={index} className="mb-3 p-2 bg-gray-600 rounded">
                        <p className="font-semibold flex items-center"><ArrowUpCircle className="text-green-400 mr-2" /> [{item.smartMoney}]</p>
                        <p>Compró [{item.amount}] Sol | Precio: {item.price}</p>
                        <p>FDV: {item.fdv} | Tiempo: {item.time}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-white">Ventas</h3>
                  <div className="bg-gray-700 p-4 rounded-lg h-[400px] overflow-y-auto">
                    <div className="mb-3 p-2 bg-gray-600 rounded">
                      <p className="font-semibold flex items-center"><ArrowDownCircle className="text-red-400 mr-2" /> [juhsy]</p>
                      <p>Vendió el 70% de sus tokens</p>
                      <p>Tiempo: Hace 30m</p>
                    </div>
                  </div>
                </div>
              </div>
              <button onClick={togglePopup} className="mt-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Cerrar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const InfoItem = ({ icon, label, value }) => (
  <div className="flex items-center space-x-2">
    {icon}
    <div>
      <p className="text-gray-400 text-sm">{label}</p>
      <div className="font-semibold">{value}</div>
    </div>
  </div>
);

export default SmartMoneyCard;
